// file name: SwipeableTabs.jsx
// description: Swipeable Tab without content, only header.
// author: livedreamok

import React from "react";
import clsx from "clsx";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import styles from "./SwipeableTabs.module.css";

const SwipeableTabs = ({
  items,
  activeKey,
  selectItem,
  className,
  rightButton,
  spacingClass,
}) => {
  return (
    <div className={clsx(styles.swipeable_tab, className)}>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        className="-mb-[2px]"
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index} className={spacingClass}>
              <div
                className={clsx(
                  styles.tab_item,
                  activeKey === index ? styles.tab_item_active : ""
                )}
                onClick={() => selectItem(index)}
              >
                <span>{item}</span>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      { rightButton }
    </div>
  );
};

export default SwipeableTabs;
