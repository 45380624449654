/** @format */

//file name: index.js
//description: this is home page of this project.
//author: Supernova

import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Spin } from 'antd'

import GuestLayout from '@/layouts/guestLayout/GuestLayout'
import { productData } from '@/utils/refUtils'
import SwipeableTabs from '@/common/components/tabs/swipeableTabs/SwipeableTabs'
import { useSessionContext } from '@supabase/auth-helpers-react'

// import { AuthProvider } from "@/context";
// import { createClient } from "@supabase/supabase-js";

// import createClient from "@/lib/supabase-server";

const DynamicProductTabPanel = dynamic(
  () => import('@/common/components/tabs/static/product/ProductTab.jsx'),
  { loading: () => <Spin /> }
)

const tabList = [
  'overview',
  'dashboard',
  'chat',
  'news',
  'pulse',
  'work',
  'graph',
]

const dataList = [
  productData.overview,
  productData.dashboard,
  productData.chat,
  productData.news,
  productData.pulse,
  productData.work,
  productData.graph,
]

const HomePage = () => {
  // const [session, setSession] = useState(null);

  const router = useRouter()

  // useEffect(() => {
  //   supabase.auth
  //     .getSession()
  //     .then(({ data: { session } }) => {
  //       console.log('session: ', session)
  //       if (session === null) {
  //         // return router.push("/");
  //       } else {
  //         setSession(session);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //       // router.push("/")
  //     });

  //   supabase.auth.onAuthStateChange((_event, session) => {
  //     setSession(session);
  //   });
  // }, [router]);

  // if (session) {
  //   router.push("/chat/chats");
  // }

  const { error, isLoading, session } = useSessionContext()

  // if (session) {
  //   const latest_thread_id =
  //     typeof window !== 'undefined'
  //       ? localStorage.getItem('latest_thread_id') ?? 'latest'
  //       : 'latest'
  //   router.push(
  //     '/chat/chats?' +
  //       new URLSearchParams({ thread_id: latest_thread_id }).toString()
  //   )
  // }

  const [currentTab, setCurrentTab] = useState(0)
  // const changeTab = (index) => {
  //   console.log("how are you", index);
  // };

  // useEffect(() => {
  //   const category = tabList[currentTab]
  //   if (currentTab > 0) {
  //     router.push(`?${category}`)
  //   } else {
  //     router.push('')
  //   }
  // }, [currentTab])

  // const supabase = createClient(
  //   process.env.NEXT_PUBLIC_SUPABASE_URL,
  //   process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
  // );

  // const supabase = createClient();
  // const {
  //   data: { session },
  // } = supabase.auth.getSession();

  // const accessToken = session?.access_token || null;

  return (
    <div className="mx-0 md:mx-[25px] lg:mx-[47px]">
      <SwipeableTabs
        items={tabList}
        activeKey={currentTab}
        selectItem={index => setCurrentTab(index)}
        spacingClass="mr-[42px] md:mr-auto last:mr-0"
      />
      <div>
        <DynamicProductTabPanel data={dataList[currentTab]} />
      </div>
    </div>
  )
}

HomePage.Layout = GuestLayout

export default HomePage
